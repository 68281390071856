<template>
  <content-block class="cl-summary">
    <div slot="instructions">
      <h5>{{ $t('Your Plan') }}</h5>
      <p>{{ $t('Overview of your plan and number of active stations today.') }}</p>
    </div>
    <div class="body-content grid mb-4" v-for="fee in fees" :key="fee.planName">
      <div class="plan-name">
        <h6>{{ $t('Name of the plan') }}</h6>
        <span class="statvalue">{{ fee.planName }}</span>
      </div>
      <div class="stationcount">
        <h6>{{ $t('Number of Stations') }}</h6>
        <span class="statvalue">{{ fee.stationCount }}</span>
      </div>
      <div class="monthlyrate">
        <h6>{{ $t('Rate per Station') }}</h6>
        <span class="statvalue">{{ fee.monthlyRate }}</span>
      </div>
      <div class="total">
        <h6>{{ $t('Subscription Fees') }}</h6>
        <span class="statvalue">{{ fee.total }}</span>
      </div>
    </div>
  </content-block>
</template>

<script>
import Vue from 'vue';
import Component from 'vue-class-component';
import ContentBlock from '@/core/shared/components/ContentBlock';

@Component({
  components: {
    ContentBlock,
  },
  props: {
    fees: {
      type: Array,
      required: true,
    },
  },
})
export default class SubscriptionSummary extends Vue {

}
</script>

<style scoped lang="postcss">
@import 'core/shared/styles';

.cl-summary {

  & h6 {
    @apply --f8;
    margin: 0;
    margin-bottom: var(--spacingXS);
    color: var(--colorManatee);
  }
  & .statvalue {
    @apply --f3;
    margin: 0;
  }
  & .grid {
    display: grid;
    justify-content: space-between;
    grid-gap: var(--spacingMd);
    grid-template-columns: repeat(1, auto);
    @media(--tablet) {
      grid-template-columns: repeat(4, 1fr);
    }
    /* grid-template-columns: repeat(auto-fit, minmax(33%, 1fr) ); */
  }
}
</style>
