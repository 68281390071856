<script>
import { connect } from 'vuex-connect';
import store from '@/core/store';
import CheckoutSummary from './CheckoutSummary';

import { get } from 'lodash-es';

store.registerModule(['vuex-connect', 'CheckoutSummary'], {
  namespaced: true,
  getters: {
    stationCount: (state, getters, rootState, rootGetters) => {
      return String(get(rootGetters, 'currentOrganization.currentStationCount'));
    },
    stationUnitRate: (state, getters, rootState, rootGetters) => {
      return `${get(rootGetters, 'currentOrganization.currentStationUnitRate.displayValue', '')} USD`;
    },
  },
});
export default connect({
  stateToProps: {
    proratedAmount: state => state.billing.proratedAmount,
    loadingProratedAmount: state => state.billing.loadings.proratedAmount,
  },
  gettersToProps: {
    stationCount: 'CheckoutSummary/stationCount',
    pricePerStation: 'CheckoutSummary/stationUnitRate',
  },
  lifecycle: {
    created: async ({ dispatch, commit }) => {
      commit('startBillingLoading', 'proratedAmount');
      try {
        await dispatch('getProratedAmount');
      } finally {
        commit('endBillingLoading', 'proratedAmount');
      }
    },
  },
  propsToProps: ['isMonthlySubscription'],
})(CheckoutSummary);
</script>
