<template>
  <content-block class="charge-summary">
    <div slot="instructions">
      <h5>{{ $t('Summary of Charges to Date') }}</h5>
      <p v-if="$permissions['content-only']">{{ $t('This is a summary of charges incurred this month to date for all the stations associated with this account. The charges include all of your commercial licensing fees this month to date and any outstanding balance or adjustments to your account.') }}</p>
      <p v-else>{{ $t('This is a summary of charges incurred this month to date. The charges include subscription fees based on your plan and the number of active stations this month to date. The charges also include your upcoming commercial licensing fees and any outstanding balance or adjustments to your account.') }}</p>
    </div>
    <div class="body-content f5 flex items-center">
      <div class="detailswrapper flex-auto">
        <!-- <div class="br b--geyser pr4"> -->
        <h6 class="headertitle">{{ $t('Details') }}</h6>
        <ul class="feelist unstyled">
          <li class="flex subscriptionfee" v-if="!$permissions['content-only']">
            <i18n class="f4" path="{month} Subscription Fees">
              <template #month>{{ incomingMonth }}</template>
            </i18n>
            <span class="price ml-auto">{{ fees.subscriptionFee.displayValue }}</span>
          </li>
          <li class="flex clfee">
            <i18n class="f4" path="{month} Commercial Licensing Fees">
              <template #month>{{ currentMonth }}</template>
            </i18n>
            <span class="price ml-auto">{{ fees.clFee.displayValue }}</span>
          </li>
          <li class="flex balance" v-if="balance">
            <span class="f4">{{ $t('Outstanding Balance') }}</span>
            <span class="price ml-auto _yellow">{{ balance }}</span>
          </li>
          <li class="flex credit" v-if="credit">
            <span class="f4">{{ $t('Account Credit') }}</span>
            <span class="price ml-auto _green">-{{ credit }}</span>
          </li>
          <li class="flex credit" v-if="fees.subscriptionDiscountsTotal.value">
            <span class="f4">{{ $t('Subscription Discount') + discountRateCaption }}</span>
            <span class="price ml-auto _green">-{{ fees.subscriptionDiscountsTotal.displayValue }}</span>
          </li>
        </ul>
        <div class="flex items-center bt b--geyser mt3 pt3 totalfee">
          <span class="f4" v-if="$permissions['content-only']">{{ $t('Total in USD') }}</span>
          <span class="f4" v-else>{{ $t('Total') }}</span>
          <span class="price ml-auto">{{ fees.total.displayValue }}</span>
        </div>
        <i18n v-if="$permissions['content-only']" class="scheduleddate f7 slate italic" path="Auto pay scheduled for {date}">
          <template #date>{{ autopayScheduledDate }}</template>
        </i18n>
        <!-- </div> -->
      </div>
      <div v-if="!$permissions['content-only']" class="tc pl4 estimatedamountwrapper">
        <h6 class="headertitle">{{ $t('Estimated Amount Due') }}</h6>
        <p class="mb2 estimatedamount">
          <span class="totalprice f1 dib v-mid mr2">{{ fees.total.displayValue }}</span>
          <span class="currency f3 dib v-mid">USD</span>
        </p>
        <i18n class="scheduleddate f7 slate italic" path="Auto pay scheduled for {date}">
          <template #date>{{ autopayScheduledDate }}</template>
        </i18n>
      </div>
    </div>
  </content-block>
</template>

<script>
import Vue from 'vue';
import Component from 'vue-class-component';
import ContentBlock from '@/core/shared/components/ContentBlock';
import moment from 'moment';

@Component({
  components: {
    ContentBlock,
  },
  props: {
    fees: {
      type: Object,
      required: true,
    },
    credit: {
      type: String,
    },
    balance: {
      type: String,
    },
  },
})
export default class ChargeSummary extends Vue {
  get incomingMonth () { return moment().add(1, 'months').format('MMMM'); }
  get currentMonth () { return moment().format('MMMM'); }
  get autopayScheduledDate () { return moment().startOf('month').add(1, 'months').format('LL'); }
  get discountRateCaption () {
    if (this.fees && this.fees.subscriptionDiscountsRate) {
      return ' (' + this.fees.subscriptionDiscountsRate + ')';
    }
    return '';
  }
}
</script>

<style scoped lang="postcss">
@import "core/shared/styles";

.charge-summary {
  & .headertitle {
    @apply --f8;
    color: var(--colorManatee);
    margin-bottom: var(--spacingXS);
  }
  & .grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    justify-content: space-between;
    /* align-items: self-start; */
  }
  & .span2 {
    grid-column: 1 / span 2;
  }
  & .disablebtn {
    @apply --buttonGhost;
  }
  & .estimatedamountwrapper {
    order: 1;
    @media (--tablet) {
      order: 2;
      border-left: 0.1rem solid var(--colorGeyser);
    }
  }
  & .feelist {
    & > * {
      margin-bottom: var(--spacingXS);
    }
  }
  & .detailswrapper {
    order: 2;
    width: 100%;
    margin-top: var(--spacingLg);
    @media (--tablet) {
      order: 1;
      width: 80%;
      margin-top: 0;
      padding-right: var(--spacingMd);

    }
  }
  & .body-content {
    flex-direction: column;
    @media (--tablet) {
      flex-direction: row;
    }
  }
}
</style>
