<template>
<transition name="expand" @after-enter="transitionDone = true" @leave="transitionDone = false">
  <div class="expandcontainer" :style="{ height: `${computedHeight}` }" v-show="expanded">
    <template v-if="useVif">
      <slot v-if="expanded"/>
    </template>
    <template v-else>
      <slot v-show="expanded"/>
    </template>
  </div>
</transition>
</template>

<script>
import Vue from 'vue';
import Component from 'vue-class-component';
import { throttle } from 'lodash-es';

@Component({
  props: {
    expanded: {
      required: true,
      type: Boolean,
    },
    useVif: {
      type: Boolean,
    },
  },
})
export default class Expandable extends Vue {
  computedHeight = 0
  transitionDone = false
  computeHeight () {
    this.computedHeight = this.transitionDone ? 'auto' : `${this.$el.scrollHeight}px`;
    // this.computedHeight = this.$el.children[0].scrollHeight
  }

  created () {
    this.$nextTick(() => {
      this.computeHeight();
      this.$watch('expanded', this.computeHeight);
    });
    this._resizeHandler = throttle(() => {
      this.computeHeight();
    }, 300);
    window.addEventListener('resize', this._resizeHandler, true);
  }

  destroyed () {
    window.removeEventListener('resize', this._resizeHandler);
  }
}
</script>

<style scoped lang="postcss">
.expandcontainer {
  overflow: hidden;
  transition: height .4s ease-in-out;
}
.expand-enter, .expand-leave-to {
  height: 0!important;
}

</style>
