<template>
  <div class="billing-invoices">
    <content-block v-if="invoices">
      <div slot="instructions">
        <h5>{{ $t('Your Invoice History') }}</h5>
        <p v-if="$permissions['content-only']">{{ $t('All of your previous invoices are displayed here. It includes your monthly licensing fees and your license purchases.') }}</p>
        <p v-else>{{ $t('All of your previous invoices are displayed here. It includes your subscription fees, your monthly licensing fees and your license purchases.') }}</p>
      </div>
      <div class="body-content">
        <div class="loadingwrapper" v-if="loading">
          <Loading class="loading" />
        </div>
        <template v-else>
          <div class="table" v-if="invoices.length !== 0">
            <div class="thead bb b--slate bw1 pb3 mv3">
              <div class="th f-label storm">
                <span class>{{ $t('Date') }}</span>
              </div>
              <div class="th f-label storm">
                <span class>{{ $t('Amount') }}</span>
              </div>
              <div class="th f-label storm">
                <span class>{{ $t('Download') }}</span>
              </div>
            </div>
            <div class="invoiceitem tr" v-for="invoice of invoices" :key="invoice.id">
              <div class="td">
                <h6>{{ formatDisplayDate(invoice.invoiceDate, { utc: true }) }}</h6>
                <small class="small">{{ invoice.type }}</small>
              </div>
              <div class="td">
                <strong>{{ invoice.total }}</strong>
                <!-- <span v-if="invoice.paidAt">{{ datePrettifier(invoice.paidAt) }}</span> -->
                <span v-if="!invoice.paidAt" class="not-paid">{{ $t('Not Paid Yet') }}</span>
              </div>
              <div class="td">
                <UIButton
                  tiny
                  icon="download"
                  class="downloadbtn"
                  :href="invoice.downloadLink"
                  rel="noopener"
                  target="_blank"
                >PDF</UIButton>
              </div>
            </div>
          </div>
          <div class="empty f5" v-if="invoices.length === 0">
            <UIIcon class="erroricon" name="error"></UIIcon>
            {{ $t('No invoices on file.') }}
          </div>
        </template>
      </div>
    </content-block>
  </div>
</template>

<script>
import Vue from 'vue';
import Component from 'vue-class-component';
import ContentBlock from '@/core/shared/components/ContentBlock';
import UIIcon from '@/core/shared/components/ui/UIIcon';
import UIButton from '@/core/shared/components/ui/UIButton';

import Loading from '@/core/shared/components/Loading';
import { formatDisplayDate } from '@/core/helpers/FormatHelpers';

@Component({
  components: {
    ContentBlock,
    UIIcon,
    UIButton,
    Loading,
  },
  methods: {
    formatDisplayDate,
  },
})
export default class BillingInvoices extends Vue {
  loading = false;

  get invoices () {
    return this.$store.getters.netInvoices;
  }

  async created () {
    this.loading = true;
    this.$store.commit('setLoading', true);

    try {
      await this.$store.dispatch('getNetInvoices');
    } finally {
      this.$store.commit('setLoading', false);
      this.loading = false;
    }
  }
}
</script>

<style scoped lang="postcss">
@import "core/shared/styles";

.billing-invoices {
  & .table {
    @apply --f5;
    & .thead {
      color: var(--colorManatee);
    }
    & .tr {
      border-bottom: 1px solid var(--colorGeyser);
      padding: var(--spacingSm) 0;
      &:last-child {
        border-bottom: none;
      }
    }
    & .thead,
    & .tr {
      display: flex;
      flex-direction: row;
    }
    & .td,
    & .th {
      text-align: left;
    }
    & .th + .th,
    & .td + .td {
      flex-grow: 1;
    }
    & .thead .th:first-child,
    & .tr .td:first-child {
      min-width: 50%;
    }
    & .thead .th:last-child,
    & .tr .td:last-child {
      flex-grow: 0;
    }
    & .icon {
      width: 1em;
    }
    & .small {
      @apply --f6;
      color: var(--colorManatee);
    }
  }
  & .downloadbtn {
    text-decoration: none;
    @apply --f8;
    color: var(--colorSBRedFlat);
    & .icon {
      fill: var(--colorSBRedFlat);
      stroke: var(--colorSBRedFlat);
      stroke-width: 0.5px;
    }
    margin-right: 1.5rem;
  }
  & .empty {
    text-align: center;
  }
  & .erroricon {
    fill: var(--colorGeyser);
    width: 10%;
    display: block;
    margin: 0 auto;
    margin-bottom: var(--spacingMd);
  }
  & .loading {
    display: inline-block;
  }
  & .loadingwrapper {
    text-align: center;
  }
}
</style>
