<template>
  <content-block class="trial-countdown">
    <div slot="instructions">
      <h5>{{ $t('Your Trial') }}</h5>
      <p>{{ $t('You are currently on a SpringboardVR free trial. After your trial ends, your account will be disabled unless you upgrade to a paid subscription.') }}</p>
    </div>
    <div class="body-content">
      <h6 class="headertitle f-tiny">{{ $t('Trial Remaining') }}</h6>
      <div class="days">
        <strong class="remainingdays">{{ daysRemaining }}</strong>
        {{ $t('Days') }}
      </div>
      <i18n tag="p" class="expirationdate" v-if="isExpired" path="Ended on {date}">
        <template #date>{{ trialExpiryFormatted }}</template>
      </i18n>
      <i18n tag="p" class="expirationdate" v-else path="Ends on {date}">
        <template #date>{{ trialExpiryFormatted }}</template>
      </i18n>
    </div>
  </content-block>
</template>

<script>
import Vue from 'vue';
import Component from 'vue-class-component';
import ContentBlock from '@/core/shared/components/ContentBlock';
import moment from 'moment';

@Component({
  components: {
    ContentBlock,
  },
  props: {
    trialExpiry: {
      validator (val) { return moment(val).isValid(); },
    },
  },
})
export default class TrialCountdown extends Vue {
  get momentExpiration () {
    return moment(this.trialExpiry);
  }

  get daysFromNow () {
    return this.momentExpiration.diff(moment(), 'days');
  }

  get isExpired () {
    return this.daysFromNow <= 0;
  }

  get daysRemaining () {
    return Math.max(this.daysFromNow, 0);
  }

  get trialExpiryFormatted () {
    return this.momentExpiration.format('LL');
  }
}
</script>

<style scoped lang="postcss">
@import "core/shared/styles";

.trial-countdown {
  & .body-content {
    text-align: center;
    @media (--tablet) {
      text-align: left;
    }
  }
  & .expirationdate {
    @apply --f8;
    font-style: italic;
    color: black;
  }
  & .remainingdays {
    color: var(--colorRed);
    margin-right: var(--spacingXS);
    display: inline-block;
    font-size: 3rem;
    font-weight: 600;
  }
  & .days {
    @apply --f4;
    margin: var(--spacingSm) 0;
  }
}
</style>
