<template>
  <content-block class="start-subscription" :loading="loading">
    <div slot="instructions">
      <h5>{{ $t('Start your Subscription') }}</h5>
      <p>{{ $t('Start your subscription and gain access to advanced features, including to our marketplace where you can license over 200 titles on a per-minute basis.') }}</p>
    </div>
    <div class="body-content grid">
      <div v-for="billingOption in billingOptions"
        :key="billingOption.value"
        :class="{ active: selectedBillingOption === billingOption.value }"
        class="billing-option"
        @click="selectedBillingOption=billingOption.value">
        <div class>
          <h6 class="headertitle f-tiny">{{ $t(billingOption.label) }}</h6>
          <p class="price">
            <strong class="priceem">{{ billingOption.price.displayValue }}</strong>
            {{ $t('USD') }}
          </p>
        </div>
        <ul class="featureslist">
          <li v-for="item in billingOption.featureslist" :key="item">{{ $t(item) }}</li>
        </ul>
      </div>
      <button
          :disabled="!selectedBillingOption"
          class="upgradebtn"
          type="button"
          name="button"
          @click="expand"
          v-show="!expanded"
        >{{ $t('Subscribe') }}</button>
    </div>
    <Accordeon id="credit-card-from" :expanded.sync="expanded" :useVif="true">
      <template v-if="creditCard">
        <CheckoutSummaryConnected :isMonthlySubscription="isMonthlySubscription"/>
        <FormTemplate
          @cancel="collapse"
          @submit="subscribe"
          :valid="!isLoadingProratingAmount"
          :submitText="$t('Subscribe')"
        />
      </template>
      <StripeCreditCardForm
        class="ccform"
        @cancel="collapse"
        @submit="subscribe"
        :organization="organization"
        loadingCategory="subscription"
        :valid="!isLoadingProratingAmount"
        :submitText="$t('Subscribe')"
        v-else
      >
        <CheckoutSummaryConnected :isMonthlySubscription="isMonthlySubscription" class="insidecc"/>
      </StripeCreditCardForm>
    </Accordeon>
  </content-block>
</template>

<script>
import Vue from 'vue';
import Component from 'vue-class-component';
import ContentBlock from '@/core/shared/components/ContentBlock';
import Accordeon from '../shared/components/Accordeon';
import StripeCreditCardForm from '../shared/components/StripeCreditCardForm';
import FormTemplate from '../shared/components/FormTemplate';
import CheckoutSummaryConnected from './StartSubscription/CheckoutSummaryConnected';
@Component({
  components: {
    StripeCreditCardForm,
    Accordeon,
    ContentBlock,
    FormTemplate,
    CheckoutSummaryConnected,
  },
  props: {
    unitRate: {
      required: true,
    },
    plans: {
      type: Array,
      required: true,
    },
    organization: {
      type: Object,
      required: true,
    },
    plan: {
      required: true,
    },
    creditCard: {
      validator (val) {
        return val instanceof Object || val === null || val === undefined;
      },
    },
    open: {
      type: Boolean,
      default: false,
    },
    loading: Boolean,
  },
  computed: {
    billingPlansByInterval () {
      return this.plans.reduce((acc, plan) => {
        if (!acc[plan.interval]) {
          acc[plan.interval] = [];
        }
        acc[plan.interval].push(plan);
        return acc;
      }, {});
    },
    isMonthlySubscription () {
      return this.selectedBillingOption === 'month';
    },
  },
})
export default class StartSubscription extends Vue {
  expanded = false
  created () {
    this.$watch('open', (val) => {
      this.expanded = val;
    }, { immediate: true });
  }

  get isLoadingProratingAmount () {
    return this.$store.state.billing.loadings.proratedAmount;
  }

  expand () {
    this.expanded = !this.expanded;
  }

  collapse () {
    this.expanded = false;
  }

  subscribe (creditCardToken) {
    const planIds = this.billingPlansByInterval[this.selectedBillingOption].map(plan => plan.id);
    this.$store.dispatch('startSubscription', { planIds: planIds, creditCardToken, billingInterval: this.selectedBillingOption });
  }

  data () {
    return {
      selectedBillingOption: '',
      billingOptions: [
        {
          value: 'month',
          label: 'Monthly Plan',
          price: {
            amount: 30,
            displayValue: '$30.00',
          },
          featureslist: [
            'Per month, per station',
            'Month-to-month',
            'Cancel anytime',
          ],
        },
        {
          value: 'year',
          label: 'Yearly Plan',
          price: {
            amount: 300,
            displayValue: '$300.00',
          },
          featureslist: [
            'Per year, per station',
            'Year-to-Year',
            'Cancel anytime',
          ],
        },
      ],
    };
  }
}
</script>

<style scoped lang="postcss">
@import "core/shared/styles";

.start-subscription {
  & .headertitle {
    margin-bottom: var(--spacingXS);
  }
  & .grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: var(--spacingMd);
    justify-content: space-between;
    align-items: center;
    @media (--tablet) {
      grid-gap: var(--spacingMd);
      grid-template-columns: repeat(2, 1fr);
    }
  }
  & .billing-option {
    text-align: center;
    padding: var(--spacingMd);
    box-shadow: var(--shadowSm);
    border: 1px solid var(--colorGeyser);
    border-radius: var(--radius);
    cursor: pointer;

    &:hover {
      background-color: var(--colorCasper);
    }

    &.active {
      background-color: var(--colorSlate);
      border-color: var(--colorSlate);
      color: var(--colorWhite);

      .featureslist {
        color: var(--colorWhite);
      }
    }
  }
  & .price {
    @apply --f3;
  }
  & .featureslist {
    @apply --f8;
    line-height: 2rem;
    color: var(--colorStorm);
    list-style: none;
    padding-left: 0;
  }
  & .priceem {
    font-size: 3rem;
    font-weight: 600;
  }
  & .upgradebtn {
    @apply --buttonPrimary;
    grid-column: span 2;
    @media (--tablet) {
    }
  }
  & .ccform {
    margin-top: var(--spacingMd);
  }
  & >>> .checkout-summary {
    margin: var(--spacingMd) 0;
  }
}
</style>
