<template>
  <content-block class="disable-account">
    <div slot="instructions">
      <h5>{{ $t('Cancel your Account') }}</h5>
      <p v-if="$permissions['content-only']">{{ $t('As soon as you disable your account, you will be billed one last time for your remaining balance owed and no longer be able to use any SpringboardVR features.') }}</p>
      <p v-else>{{ $t('You will lose access to all SpringboardVR features once disabled. Any remaining balance will be billed one last time on the 1st of next month.') }}</p>
    </div>
    <div class="body-content f5 grid">
      <i18n
        class="caption"
        path="If you change your mnind after cancelling your account, you can always log back in with your current email and password and reactivate it. If you prefer, you can permanently {delete}."
      >
        <template #delete>
          <button class="deletebtn" @click="deleteAccount">{{ $t('delete your account') }}</button>
        </template>
      </i18n>
      <UIButton
        class="disablebtn w-100"
        icon="disable"
        ghost
        @click="disableAccount"
      >{{ $t('Cancel Account') }}</UIButton>
    </div>
  </content-block>
</template>

<script>
import Vue from 'vue';
import Component from 'vue-class-component';
import ContentBlock from '@/core/shared/components/ContentBlock';
import UIIcon from '@/core/shared/components/ui/UIIcon';
import UIButton from '@/core/shared/components/ui/UIButton';

@Component({
  components: {
    ContentBlock,
    UIIcon,
    UIButton,
  },
})
export default class DisableAccount extends Vue {
  disableAccount () {
    this.$emit('disable-account');
  }

  deleteAccount () {
    this.$emit('delete-account');
  }
}
</script>

<style scoped lang="postcss">
@import "core/shared/styles";

.disable-account {
  & .headertitle {
    @apply --f8;
    margin-bottom: var(--spacingXS);
  }
  & .grid {
    display: grid;
    grid-template-columns: 1fr;
    justify-content: space-between;
    align-items: self-start;
    grid-gap: var(--spacingMd);
    @media (--tablet) {
      grid-gap: var(--spacingMd);
      grid-template-columns: repeat(3, 1fr);
    }
  }
  & .caption {
    grid-column: auto;
    @media (--tablet) {
      grid-column: span 2;
    }
  }
  & .disableicon {
    width: 1.4rem;
  }
  & .deletebtn {
    @apply --buttonUnstyled;
    @apply --linkStyle;
  }
}
</style>
