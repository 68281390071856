<template>
  <div class="credit-card-form">
    <div class="popup-note">{{ $t('NOTE: Your bank may show a pop-up for authentication. Please disable your pop-up blocker!') }}</div>
    <FormTemplate @submit="onSubmit" @cancel="$emit('cancel')" :showCancel="showCancel" :valid="computedValid" :submit-text="submitText">
      <label class="labelnumber f8">
        {{ $t('Credit Card Number') }}
        <div id="cardNumber" class="StripeElement"></div>
      </label>
      <label class="labelexpiration f8">
        {{ $t('Expiration Date') }}
        <div id="cardExpiry"></div>
      </label>
      <label class="labelcvc f8">
        {{ $t('CVC') }}
        <div id="cardCvc"></div>
      </label>
      <label class="labelpostalcode f8">
        {{ $t('Zip/Postal Code') }}
        <div id="postalCode" ref="postalCode"></div>
      </label>
      <div class="infos" v-if="$slots.default && $slots.default.length">
        <slot></slot>
      </div>
    </FormTemplate>
  </div>
</template>

<script>
import Vue from 'vue';
import Component from 'vue-class-component';
import FormTemplate from './FormTemplate';
import * as Sentry from '@sentry/browser';

@Component({
  components: { FormTemplate },
  props: {
    creditCard: {
      // required: true,
      type: Object,
    },
    submitText: {
      type: String,
    },
    valid: {
      type: Boolean,
      default: true,
    },
    showCancel: {
      type: Boolean,
      default: true,
    },
    organization: {
      type: Object,
      required: true,
    },
    loadingCategory: {
      type: String,
      required: true,
    },
    // locale: {
    //   required: true,
    //   type: String
    // }
  },
})
export default class StripeCreditCardForm extends Vue {
  stripe = null;
  elements = null;
  creditCardToken = null;
  completed = {
    cardNumber: false,
    cardExpiry: false,
    cardCvc: false,
    postalCode: false,
  };

  get computedValid () {
    return this.completed.cardNumber && this.completed.cardExpiry && this.completed.cardCvc && this.completed.postalCode;
  }

  addStipeElement (elementName, divName) {
    const component = this;
    const element = this.elements.create(elementName, {
      style: {
        base: {
          color: 'inherit',
          fontWeight: '400',
          fontFamily: 'Open Sans, Helvetica, sans-serif',
          fontSize: '14px',
          fontSmoothing: 'antialiased',
          '::placeholder': {
            color: '#CFD7DF',
          },
          ':-webkit-autofill': {
            color: '#e39f48',
          },
        },
      },
      invalid: {
        color: '#E25950',
        '::placeholder': {
          color: '#FFCCA5',
        },
      },
    });
    element.mount(divName);
    element.on('change', function (event) {
      component.completed[elementName] = event.complete;
    });
  }

  mounted () {
    this.stripe = new window.Stripe(window.sbvrenv.STRIPE_KEY);
    this.elements = this.stripe.elements();

    this.addStipeElement('cardNumber', '#cardNumber');
    this.addStipeElement('cardExpiry', '#cardExpiry');
    this.addStipeElement('cardCvc', '#cardCvc');
    this.addStipeElement('postalCode', '#postalCode');
  }

  async onSubmit () {
    this.$store.commit('startBillingLoading', this.loadingCategory);
    const setupIntent = await this.$store.dispatch('createSetupIntent');
    this.stripe.confirmCardSetup(setupIntent.client_secret, {
      payment_method: {
        card: this.elements.getElement('cardNumber'),
        billing_details: {
          name: this.organization.name,
        },
      },
    }).then((result) => {
      if (result.error) {
        this.$store.commit('endBillingLoading', this.loadingCategory);
        this.$store.commit('setFlash', {
          type: 'error',
          message: this.$t('The credit card could not be verified. Please check all fields.'),
        });

        this.handleStripeError(result.error);
      } else {
        this.processSetupIntent(result.setupIntent);
        this.stripe.createToken(this.elements.getElement('cardNumber')).then((result) => {
          if (result.error) {
            this.$store.commit('endBillingLoading', this.loadingCategory);
            this.$store.commit('setFlash', {
              type: 'error',
              message: this.$t('The credit card details could not be updated. Please try again later.'),
            });

            this.handleStripeError(result.error);
          } else {
            this.creditCardToken = result.token.id;
            this.$emit('submit', this.creditCardToken);
          }
        });
      }
    });
  }

  handleStripeError (error) {
    Sentry.captureException(new Error(error?.message), {
      extra: error,
    });
  }

  async processSetupIntent (setupIntent) {
    await this.$store.dispatch('processSetupIntent', { setupIntent });
  }

  clearForm () {
    this.elements.getElement('cardNumber').clear();
    this.elements.getElement('cardExpiry').clear();
    this.elements.getElement('cardCvc').clear();
    this.elements.getElement('postalCode').clear();
  }
}
</script>

<style scoped lang="postcss">
@import "core/shared/styles";

.credit-card-form {
  & >>> .input,
  & >>> .StripeElement {
    @apply --text;
  }
  & .labelname,
  & .labelnumber,
  & .infos {
    grid-column: 1/-1;
    margin: 0;
  }
  & .labelexpiration {
    grid-column: 1/-1;
    @media (--tablet) {
      grid-column: 1;
    }
    margin: 0;
  }
  & .labelcvc {
    grid-column: 1/-1;
    @media (--tablet) {
      grid-column: 2;
    }
    margin: 0;
  }
  & .labelpostalcode {
    grid-column: 1/-1;
    @media (--tablet) {
      grid-column: 3;
    }
    margin: 0;
  }
  & .popup-note {
    @apply --f6;
    margin-bottom: 1rem;
    color: var(--colorSBRedFlat);
  }
}
</style>
