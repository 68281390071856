<template>
  <div class="billing-route">
    <sub-header v-if="!isOnTrial">
      <ul class="tabs" slot="tabs">
        <router-link tag="li" :to="{ name: 'billing-account' }">{{ $t('My Account') }}</router-link>
        <router-link tag="li" :to="{ name: 'billing-invoices' }">{{ $t('Invoice History') }}</router-link>
      </ul>
    </sub-header>
    <router-view class="container" v-bind="subcomponentProps"/>
  </div>
</template>

<script>
import Vue from 'vue';
import Component from 'vue-class-component';
import ContentBlock from '@/core/shared/components/ContentBlock';
import SubHeader from '@/core/shared/components/SubHeader';

@Component({
  components: {
    SubHeader,
    ContentBlock,
  },
})
export default class Billing extends Vue {
  created () {
    this.$store.commit('setTitle', this.$t('Billing'));
  }

  get currentOrganization () {
    return this.$store.getters.currentOrganization;
  }

  get billingPlan () {
    return this.$store.state.billing.billingPlan;
  }

  get billingPlans () {
    return this.$store.state.billing.billingPlans;
  }

  get isOnTrial () {
    return this.currentOrganization && this.currentOrganization.isOnTrial;
  }

  get subcomponentProps () {
    return {
      organization: this.currentOrganization,
      plan: this.billingPlan,
      plans: this.billingPlans,
      invoices: this.$store.getters.netInvoices,
    };
  }
}
</script>

<style lang="postcss">
@import "core/shared/styles";

._caption {
  @apply --f8;
  font-style: italic;
}
.mt-xs {
  margin-top: var(--spacingXS);
}
.mb-md {
  margin-bottom: var(--spacingMd);
}
._red {
  color: var(--colorRed);
}
._green {
  color: var(--colorGreen);
}
._yellow {
  color: var(--colorYellow);
}
.inherit {
  color: inherit;
}
</style>
<style scoped lang="postcss">
@import "core/shared/styles";

.billing-route {
  & >>> .instructions {
    @media (--desktop) {
      flex: 0 0 20rem;
      margin-right: var(--spacingSm);
    }
  }
  & >>> .f1 {
    @apply --f1;
  }
  & >>> .f2 {
    @apply --f2;
  }
  & >>> .f3 {
    @apply --f3;
  }
  & >>> .f4 {
    @apply --f4;
  }
  & >>> .f5 {
    @apply --f5;
  }
  & >>> .f7 {
    @apply --f7;
  }
  & >>> .f8 {
    @apply --f8;
  }
  & >>> .fill-fjord {
    fill: var(--colorFjord);
  }
  & >>> .b--geyser {
    border-color: var(--colorGeyser);
  }
  & >>> .slate {
    color: var(--colorSlate);
  }
  & >>> ul.unstyled {
    list-style: none;
    padding-left: 0;
  }
  & >>> ml-auto {
    margin-left: auto;
  }

  /* & .body-content { @apply --body; }
  & table { @apply --table; }
  & .label { @apply --f8
; }
  & a { @apply --buttonLink; }
  & small {
    @apply --f6;
  } */
}
</style>
