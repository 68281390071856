<template>
  <Loading class="loading" v-if="loading"/>
  <div class="billing-account" v-else-if="organization">
    <template v-if="organization.isActive">
      <template v-if="$permissions['content-only']">
        <template v-if="!organization.defaultCreditCard && organization.isAccessSuspended">
          <PaymentMethod
            :update-credit-card="updateCreditCard"
            :loading="$store.state.billing.loadings.updateCC"
            :organization="organization"
            :initialOpenState="true"
          />
        </template>

        <template v-else>
          <content-block class="latestupdate" v-if="organization.latestStatsDate">
            <template slot="additional">
              <h4 class="f-label mb-xs">{{$t('Latest Update')}}</h4>
              <h4 class="f-tiny">{{ lastUpdateFormatted }}</h4>
            </template>
          </content-block>
          <ChargeSummary
            :fees="summaryFees"
            :credit="organization.accountCredit && organization.accountCredit.displayValue"
            :balance="organization.outstandingBalance && organization.outstandingBalance.displayValue"
          />
          <CLSummary :fees="clFees" v-if="!$permissions['content-only']" />
          <PaymentMethod
            :update-credit-card="updateCreditCard"
            :loading="$store.state.billing.loadings.updateCC"
            :organization="organization"
          />
        </template>
      </template>

      <template v-else>
        <template v-if="!organization.isOnTrial">
          <content-block class="latestupdate" v-if="organization.latestStatsDate">
            <template slot="additional">
              <h4 class="f-label mb-xs">{{$t('Latest Update')}}</h4>
              <h4 class="f-tiny">{{ lastUpdateFormatted }}</h4>
            </template>
          </content-block>
          <ChargeSummary
            :fees="summaryFees"
            :credit="organization.accountCredit && organization.accountCredit.displayValue"
            :balance="organization.outstandingBalance && organization.outstandingBalance.displayValue"
          />
          <CLSummary :fees="clFees" v-if="!$permissions['content-only']" />
          <SubscriptionSummary
            :fees="subscriptionFees"
            v-if="Number(organization.currentStationCount) !== 0"
          />
        </template>

        <template v-else-if="organization.isOnTrial">
          <TrialCountdown :trialExpiry="organization.trialExpiry"/>
          <StartSubscription
            :plans="plans"
            :plan="plan"
            :organization="organization"
            :unitRate="organization.currentStationUnitRate && organization.currentStationUnitRate.displayValue"
            :open="openSubscription"
            :credit-card="organization.defaultCreditCard"
            :loading="$store.state.billing.loadings.subscription"
          />
        </template>

        <PaymentMethod
          v-if="!organization.isOnTrial"
          :update-credit-card="updateCreditCard"
          :loading="$store.state.billing.loadings.updateCC"
          :organization="organization"
        />
      </template>

      <DisableAccount
        @disable-account="showModal('disable')"
        @delete-account="showModal('delete')"
      />
      <DisableAccountModal
        :type="$store.state.billing.disableAccountModalType"
        :open="$store.state.billing.isDisableAccountModalVisible"
        v-if="$store.state.billing.isDisableAccountModalVisible"
        @cancel="hideModal"
        @submit="disableAccount"
      />
    </template>
    <template v-else-if="$permissions['content-only']">
      <i18n class="f4 contentonlydisabled" path="Your account is disabled, please email {supportEmail} to request your account become reactivated." tag="p">
        <template #supportEmail>
          <a class="link-style" href="mailto:support@springboardvr.com">support@springboardvr.com</a>
        </template>
      </i18n>
    </template>
    <template v-else>
      <StartSubscription
        :plans="plans"
        :plan="plan"
        :unitRate="organization.currentStationUnitRate && organization.currentStationUnitRate.displayValue"
        :organization="organization"
        :open="openSubscription"
        :loading="$store.state.billing.loadings.subscription"
      />
    </template>
  </div>
</template>

<script>
import Vue from 'vue';
import Component from 'vue-class-component';
import ContentBlock from '@/core/shared/components/ContentBlock';
import TrialCountdown from './account/TrialCountdown';
import StartSubscription from './account/StartSubscription';
import PaymentMethod from './account/PaymentMethod';
import DisableAccount from './account/DisableAccount';
import ChargeSummary from './account/ChargeSummary';
import SubHeader from '@/core/shared/components/SubHeader';
import DisableAccountModal from './account/DisableAccountModal';
import CLSummary from './account/CLSummary';
import SubscriptionSummary from './account/SubscriptionSummary';
import Loading from '@/core/shared/components/Loading';
import { mapActions } from 'vuex';
import moment from 'moment';

@Component({
  components: {
    SubHeader,
    ContentBlock,
    TrialCountdown,
    StartSubscription,
    PaymentMethod,
    ChargeSummary,
    DisableAccount,
    DisableAccountModal,
    CLSummary,
    SubscriptionSummary,
    Loading,
  },
  props: {
    organization: {
      required: true,
    },
    plan: {
      required: true,
    },
    plans: {
      required: true,
    },
    openSubscription: {
      type: Boolean,
      default: false,
    },
  },
  methods: mapActions(['updateCreditCard', 'subscriptionPlans']),
})
export default class BillingAccount extends Vue {
  loading = false

  async created () {
    this.loading = true;
    this.$store.commit('setLoading', true);
    if (this.$route.query.disable) this.showModal('disable');
    try {
      await this.$store.dispatch('getBillingAccountInfo');
    } finally {
      this.$store.commit('setLoading', false);
      this.loading = false;
    }
  }

  get summaryFees () {
    return this.organization && this.organization.upcomingFees;
  }

  get lastUpdateFormatted () {
    return moment(this.organization.latestStatsDate).format('LLL');
  }

  get subscriptionFees () {
    if (!this.organization) return;

    return this.organization.currentSubscription.billingPlans.map(item => {
      return {
        planName: item.name,
        stationCount: item.pivot.quantity,
        monthlyRate: item.currentPricePerStation.displayValue,
        total: item.totalAmount.displayValue,
      };
    });
  }

  get clFees () {
    const {
      currentLicensedGamesCount,
      upcomingFees,
    } = this.organization;
    return {
      currentLicensedGamesCount: currentLicensedGamesCount,
      upcomingFees: upcomingFees,
    };
  }

  showModal (type) {
    this.$store.commit('setDisableAccountModalVisibility', type);
  }

  hideModal () {
    this.$store.commit('setDisableAccountModalVisibility', false);
  }

  async disableAccount ({ type, reason: { kind: reason, comment: comments } }) {
    this.hideModal();
    this.$store.commit('setLoadingOverlay', true);
    try {
      await this.$store.dispatch('disableAccount', { deletion: type === 'delete', reason, comments });
    } finally {
      this.$store.commit('setLoadingOverlay', false);
    }
  }
}
</script>

<style scoped lang="postcss">
@import "core/shared/styles";

.billing-account {
  & .titleblock {
    @apply --f8;
  }
  & .daysbeforetrial {
    color: var(--colorRed);
    font-size: 3rem;
  }
  /* & .body-content { @apply --body; }
  & table { @apply --table; }
  & .label { @apply --f8
; }
  & a { @apply --buttonLink; }
  & small {
    @apply --f6;
  } */
  & .latestupdate {
    margin-top: var(--spacingSm);

    @media (--desktop) {
      margin-top: var(--spacingMd);
    }
  }
}
</style>
