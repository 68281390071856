<template>
  <form class="form-template" @submit.prevent="$emit('submit')">
    <slot></slot>
    <div class="cancel" v-if="showCancel">
      <slot name="cancel" :valid="valid">
        <UIButton ghost @click="$emit('cancel')">{{ $t('Cancel') }}</UIButton>
      </slot>
    </div>
    <div class="submit">
      <slot name="submit" :valid="valid">
        <UIButton type="submit" class="w-100" :disabled="!valid">{{ submitText || $t('Update') }}</UIButton>
      </slot>
    </div>
  </form>
</template>

<script>
import Vue from 'vue';
import Component from 'vue-class-component';
import UIButton from '@/core/shared/components/ui/UIButton';
@Component({
  props: {
    valid: {
      type: Boolean,
      default: true,
    },
    submitText: {
      type: String,
    },
    showCancel: {
      type: Boolean,
      default: true,
    },
  },
  components: {
    UIButton,
  },
})
export default class FormTemplate extends Vue {

}
</script>

<style scoped lang="postcss">
@import "core/shared/styles";

.form-template {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: var(--spacingSm);
  & .input {
    @apply --text;
  }
  & .submit {
    grid-column: 3;
    margin: 0;
  }
  & .cancel {
    grid-column: 2;
    /* grid-area: cancel; */
    margin: 0;
    & >>> button {
      width: auto;
      margin-left: auto;
      display: block;
    }
  }
}
</style>
