<template functional>
  <div class="f5 checkout-summary">
    <h6 class="total">
      <span class="flex-auto">{{ parent.$t('Total') }}</span>
      <span v-if="!props.loadingProratedAmount">{{ props.proratedAmount }}</span>
      <span v-else>
        <Loading class="proratedamountloading" tiny/>
      </span>
    </h6>
    <i18n v-if="props.isMonthlySubscription" class="caption" path="This is the prorated amount we are charging now for {count} station for the remaining of the current month. After this, your card will be charged automatically {price} per station on the 1st of each month. You can cancel at any time.">
      <template #count>{{ props.stationCount }}</template>
      <template #price>{{ props.pricePerStation }}</template>
    </i18n>
    <i18n v-else class="caption" path="This is total amount for {count} stations for the upcoming year. After this, your card will be automatically charged $300 USD per station yearly. You can cancel your subscription any time.">
      <template #count>{{ props.stationCount }}</template>
      <template #price>{{ props.pricePerStation }}</template>
    </i18n>
  </div>
</template>

<script>
import Vue from 'vue';
import Component from 'vue-class-component';
import Loading from '@/core/shared/components/Loading';
Vue.component('Loading', Loading);
@Component({
  components: {
    Loading,
  },
  props: {
    isMonthlySubscription: {
      type: Boolean,
      default: true,
    },
    proratedAmount: {
      type: String,
    },
    stationCount: {
      required: true,
      type: String,
    },
    pricePerStation: {
      required: true,
      type: String,
    },
    loadingProratedAmount: {
      type: Boolean,
    },
  },
})
export default class CheckoutSummary extends Vue {
}
</script>

<style scoped lang="postcss">
@import 'core/shared/styles';
.checkout-summary {
  & .total {
    display: flex;
    font-weight: 700;
    padding: var(--spacingSm) 0;
    border: 1px solid var(--colorGeyser);
    border-width: 1px 0;

    margin-bottom: var(--spacingSm);

  }
}
</style>
