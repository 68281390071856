<template>
  <Modal class="disable-account-modal show" v-bind="$attrs" @closeModal="onCancel">
    <div class="pa5">
      <header class="tc mt4 mb4">
        <UIIcon name="disable" class="fill-fjord dib mb3" style="width: 60px;" />
        <p class="caption">
          <i18n
            path="We will {delete}. In the meantime, your account will be disabled."
            v-if="type === 'delete'"
          >
            <template #delete>
              <span class="_red">{{ $t('permanently delete your account within 30 days') }}</span>
            </template>
          </i18n>
          <br />
          {{
          type === 'delete'
          ? $t('Why would you like to delete your account?')
          : $t('Why would you like to disable your account?')
          }}
        </p>
      </header>
      <ValidationObserver ref="validationObserver" v-slot="{ invalid, pristine }">
        <form class="form" @submit.prevent="onSubmit">
          <UISelect
            class="reasonselect"
            :title="type === 'delete' ? $t('Reason for deleting account') : $t('Reason for disabling account')"
            :placeholder="$t('Pick a reason')"
            v-model="internalValue.kind"
            required
            vid="disableReason"
            rules="dropdown"
          >
            <option value="closing">{{$t('Business closing down')}}</option>
            <option value="another_service">{{$t('Using another service')}}</option>
            <option value="unsatisfied">{{$t('Dissatisfied with service')}}</option>
            <option value="price">{{$t('Price')}}</option>
            <option value="other">{{$t('Other')}}</option>
          </UISelect>
          <UIInput
            :title="$t('Additional Comments')"
            textarea
            height="100px"
            v-model="internalValue.comment"
            class="commenttextbox"
          />
          <span class="f-instructions">*{{$t('Required')}}</span>
          <div class="flex">
            <UIButton
              ghost
              class="cancelbtn flex-auto w-50 mr4"
              @click="onCancel"
            >{{ $t('Keep my account') }}</UIButton>
            <UIButton
              type="submit"
              name="button"
              class="submitbtn flex-auto w-50"
              :disabled="pristine || invalid"
            >{{ type === 'delete' ? $t('Delete my account') : $t('Disable my account') }}</UIButton>
          </div>
        </form>
      </ValidationObserver>
    </div>
  </Modal>
</template>

<script>
import Vue from 'vue';
import Component from 'vue-class-component';
import Modal from '@/core/shared/components/Modal';
import UIIcon from '@/core/shared/components/ui/UIIcon';
import UIButton from '@/core/shared/components/ui/UIButton';
import UISelect from '@/core/shared/components/ui/UISelect';
import UIInput from '@/core/shared/components/ui/UIInput';
import { ValidationObserver } from 'vee-validate';

@Component({
  components: {
    UIIcon,
    UIInput,
    UISelect,
    UIButton,
    Modal,
    ValidationObserver,
  },
  props: {
    type: {
      default: 'disable',
    },
    reason: {
      type: Object,
      default: () => ({
        kind: null,
        comment: null,
      }),
    },
  },
  watch: {
    reason: {
      handler (val) {
        this.internalValue = { ...val };
      },
      immediate: true,
      deep: true,
    },
    internalValue: {
      handler (val) {
        this.$emit('update:reason', val);
      },
      immediate: true,
      deep: true,
    },
  },
})
export default class DisableAccountModal extends Vue {
  internalValue = {
    kind: null,
    comment: null,
  }

  onSubmit () {
    this.$refs.validationObserver.validateWithInfo().then(({ isValid, errors }) => {
      if (isValid) {
        this.$emit('submit', { type: this.type, reason: this.internalValue });
      }
    });
  }

  onCancel () {
    this.$emit('cancel');
  }
}
</script>

<style scoped lang="postcss">
@import "core/shared/styles";

.disable-account-modal {
  @apply --body;
  & .form {
    & > * + * {
      margin-top: var(--spacingSm);
    }
  }
  & >>> .panel {
    min-width: 35%;
    @media (--tablet) {
      max-width: 60%;
    }
    @media (--desktop) {
      max-width: 40%;
    }
  }
}
</style>
