<template>
  <content-block class="payment-method" :loading="loading">
    <div slot="instructions">
      <h5>{{ $t('Your Payment Method') }}</h5>
      <p class="instructionstext" v-if="isContentOnlyInitialVisit">{{ $t('Add your credit card and start using SpringboardVR to license hundreds of titles.') }}</p>
      <p class="instructionstext" v-else>{{ $t('Your current card on file.') }}</p>
    </div>
    <div class="body-content">
      <div class="grid carddetails" v-if="!isContentOnlyInitialVisit">
        <div class="span2">
          <h6 class="headertitle">{{ $t('Your Credit Card') }}</h6>
          <p class="f5 cardpreview">
            <span v-if="organization.defaultCreditCard">
              <span class="cardpreview">{{ cardPreview }}</span>
              <span class="expiration">{{ $t('Expires') }}: {{ expirationDate }}</span>
            </span>
            <span v-else>{{ $t('No Card Found') }}</span>
          </p>
          <p class="f5 expiredcaption" v-if="organization.creditCardExpired">
            <span>{{ $t('Your credit card has expired, please update your information.') }}</span>
          </p>
          <p class="f5 paymenterrorcaption" v-if="organization.paymentErrorDetail">
            <i18n path="Your last payment failed. {readmore}">
              <template #readmore>
                <span class="paymenterrormsg">
                  <br />
                  {{ organization.paymentErrorDetail.message }}
                </span>
              </template>
            </i18n>
          </p>
        </div>
        <button
          class="updatecardbtn"
          type="button"
          name="button"
          @click="showForm"
          v-show="!shouldShowForm"
        >
          <span v-if="organization.defaultCreditCard">{{ $t('Update Card') }}</span>
          <span v-else>{{ $t('Add Card') }}</span>
        </button>
      </div>
      <Accordeon id="credit-card-from" :expanded.sync="shouldShowForm">
        <StripeCreditCardForm
          class="form"
          ref="form"
          @submit="onSubmit"
          @cancel="hideForm"
          :organization="organization"
          loadingCategory="updateCC"
          :showCancel="!isContentOnlyInitialVisit"
          :submitText="organization.defaultCreditCard ? $t('Update Card') : $t('Add Card')"
        ></StripeCreditCardForm>
      </Accordeon>
    </div>
  </content-block>
</template>

<script>
import Vue from 'vue';
import Component from 'vue-class-component';
import ContentBlock from '@/core/shared/components/ContentBlock';
import Accordeon from '../shared/components/Accordeon';
import StripeCreditCardForm from '../shared/components/StripeCreditCardForm';
import { padStart } from 'lodash-es';

@Component({
  components: {
    ContentBlock,
    StripeCreditCardForm,
    Accordeon,
  },
  props: {
    organization: {
      required: true,
      type: Object,
    },
    loading: Boolean,
    updateCreditCard: {
      type: Function,
      required: true,
    },
    initialOpenState: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
})
export default class PaymentMethod extends Vue {
  shouldShowForm = this.initialOpenState;

  get cardPreview () {
    return `${this.organization.defaultCreditCard.brand} - XXXX XXXX XXXX ${
      this.organization.defaultCreditCard.last4
    }`;
  }

  get expirationDate () {
    return `${padStart(this.organization.defaultCreditCard.expiryMonth, 2, '0')}/${this.organization.defaultCreditCard.expiryYear}`;
  }

  get isContentOnlyInitialVisit () {
    return this.$permissions['content-only'] && this.initialOpenState;
  }

  showForm () {
    this.shouldShowForm = !this.shouldShowForm;
  }

  hideForm () {
    this.shouldShowForm = false;
  }

  async onSubmit (creditCardToken) {
    await this.updateCreditCard({ creditCardToken });
    this.$refs.form.clearForm();
    this.shouldShowForm = false;
  }
}
</script>

<style scoped lang="postcss">
@import "core/shared/styles";

.payment-method {
  & .headertitle {
    @apply --f6;
    margin-bottom: var(--spacingXS);
  }
  & .grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    @media (--tablet) {
      grid-template-columns: repeat(3, 1fr);
      grid-gap: var(--spacingSm);
    }
    grid-gap: var(--spacingMd);
    justify-content: space-between;
    align-items: self-start;
  }
  & .span2 {
    grid-column: 1 / span 2;
  }
  & .updatecardbtn {
    @apply --buttonSecondary;
    grid-column: span 2;
    @media (--tablet) {
      grid-column: auto;
    }
  }
  & .carddetails {
    margin-bottom: var(--spacingMd);
  }
  & .cardpreview {
    display: block;
  }
  & .expiration {
    display: block;
  }
  & .expiredcaption,
  & .paymenterrorcaption,
  & .paymenterrormsg {
    @apply --f8;
    font-size: 1.1rem;
    color: var(--colorYellow);
    margin-top: var(--spacingXS);
    font-weight: 700;
  }
}
</style>
