<template>
  <content-block class="cl-summary">
    <div slot="instructions">
      <h5>{{ $t('Commercial Licensing') }}</h5>
      <p>{{ $t('Overview of commercial licensing fees for the current month to date, not including new monthly or yearly licenses.') }}</p>
    </div>
    <div class="body-content grid">
      <div class="gamescount">
        <h6>{{ $t('Number of Licensed Titles Played this Month') }}</h6>
        <span class="statvalue">{{ fees.currentLicensedGamesCount }}</span>
      </div>
      <div class="current">
        <h6>{{ $t('Current Month Commercial Licensing Cost') }}</h6>
        <span class="statvalue">{{ fees.upcomingFees.clFee.displayValue }}</span>
      </div>
    </div>
  </content-block>
</template>

<script>
import Vue from 'vue';
import Component from 'vue-class-component';
import ContentBlock from '@/core/shared/components/ContentBlock';

@Component({
  components: {
    ContentBlock,
  },
  props: {
    fees: {
      type: Object,
      required: true,
    },
  },
})
export default class CLSummary extends Vue {

}
</script>

<style scoped lang="postcss">
@import "core/shared/styles";

.cl-summary {
  & h6 {
    @apply --f8;
    margin: 0;
    margin-bottom: var(--spacingXS);
    color: var(--colorManatee);
  }
  & .statvalue {
    @apply --f3;
    margin: 0;
  }
  & .grid {
    display: grid;
    justify-content: space-between;
    grid-gap: var(--spacingMd);
    grid-template-columns: repeat(1, auto);
    @media (--tablet) {
      grid-template-columns: repeat(3, 1fr);
    }
    /* grid-template-columns: repeat(auto-fit, minmax(33%, 1fr) ); */
  }
}
</style>
