<script>
import Vue from 'vue';
import { generateId } from '@/core/shared/helpers/VariousHelper';
import Component from 'vue-class-component';
import Expandable from './Expandable';
const accordeonVue = new Vue();

@Component({
  props: {
    id: {
      required: true,
      type: String,
    },
    ...Expandable.options.props,
  },
})
export default class Accordeon extends Vue {
  _id = generateId()
  localValue = null
  created () {
    accordeonVue.$on('close', () => {
      this.localValue = false;
    });
    this.$watch('expanded', (newVal, oldVal) => {
      if (newVal === true) {
        accordeonVue.$emit('close');
        this.localValue = true;
      } else {
        this.localValue = false;
      }
    }, { immediate: true });
    this.$watch('localValue', (newVal, oldVal) => {
      this.$emit('update:expanded', newVal);
    });
  }

  render (h) {
    return h(Expandable, {
      attrs: this.$attrs,
      props: { expanded: this.localValue },
    }, this.$slots.default);
  }
}
</script>

<style scoped lang="postcss">

</style>
